import { Box, Flex, Heading, IconButton, Button, Text, HStack } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import PoweredBy from './powered-by';
import T from 'src/renderer/local-text';
import { DeleteIcon } from '@chakra-ui/icons';

interface AudioRecorderProps {
    title: string;
    onSubmit: (audioUrl: string) => void;
    disabled: boolean;
}

const getPreferredMimeType = () => {
    const preferredMimeTypes = [
        'audio/mp4',
        'audio/ogg',
        'audio/mpeg',
    ];

    for (const mimeType of preferredMimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
            return mimeType;
        }
    }

    return null;
}

export const InnerAudioRecorder = ({ audioUrl, setAudioUrl, disabled }) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunks = useRef<Array<Blob>>([]);
    const streamRef = useRef<MediaStream | null>(null);
    const [mimeType, setMimeType] = useState<string | null>(null);

    const clear = () => {
        setMimeType(null)
        audioChunks.current = [];
        streamRef.current?.getTracks().forEach((track) => track.stop()); // Stop media stream
        streamRef.current = null;
    }

    React.useEffect(() => {
        if (!audioUrl) {
            clear()
        }
    }, [audioUrl])

    const startRecording = async () => {
        if (!streamRef.current) {
            streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
        }

        setIsRecording(true);
        const opts = {}
        const preferredMimeType = getPreferredMimeType()
        if(preferredMimeType) {
            opts.mimeType = preferredMimeType
        }

        mediaRecorderRef.current = new MediaRecorder(streamRef.current, opts);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            if (audioChunks.current?.length > 0) {
                const mimeType = audioChunks.current[0].type
                const audioBlob = new Blob(audioChunks.current, { type: mimeType });

                const url = URL.createObjectURL(audioBlob);

                // those 2 separate states are not ideal
                // a better approach would be to have a single state
                setAudioUrl(url)
                setMimeType(mimeType)
            }

            // we want to resume recording after stopping
            // audioChunks.current = []; // Reset chunks after processing
        };

        mediaRecorderRef.current.start(1000); // https://community.openai.com/t/whisper-problem-with-audio-mp4-blobs-from-safari/322252
    };

    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    return (
        <Flex direction="column" align="center" justify="center" >
            <Box textAlign="center" mb={4} >
                <Text color="gray.800">
                    {/* {isRecording ? 'Recording in progress...' : 'Press the button to start recording'} */}
                    <T>{isRecording ? '_audio_recorder:in_progress' : '_audio_recorder:press_button'}</T>
                </Text>
            </Box>
            <IconButton
                isDisabled={disabled}
                _hover={{ transform: 'scale(1.1)' }}
                onClick={isRecording ? stopRecording : startRecording}
                icon={isRecording ? <FaStop /> : <FaMicrophone />}
                colorScheme={isRecording ? 'red' : 'green'}
                size="2xl"
                aria-label="Record"
                isRound
                mb={4}
            />
            {audioUrl && mimeType && (
                <Box>
                    <Text fontWeight='bold'>
                        Preview
                    </Text>
                    <HStack>
                        <audio key={audioUrl} controls>
                            <source src={audioUrl} type={mimeType} />
                            Your browser does not support the audio element.
                        </audio>
                        <IconButton icon={<DeleteIcon />} size='sm' colorScheme='red' onClick={() => {
                            setAudioUrl('')
                            clear()
                        }} />
                    </HStack>
                </Box>
            )}
        </Flex>
    );
}

function AudioRecorder({ title, onSubmit, disabled }: AudioRecorderProps) {
    const [audioUrl, setAudioUrl] = useState<string>('');

    return (
        <Flex direction="column" align="center" justify="center" mt={8} p={6} borderRadius="md" bg="gray.50">
            <Heading as='h4'>
                {title}
            </Heading>
            <InnerAudioRecorder audioUrl={audioUrl} setAudioUrl={setAudioUrl} disabled={disabled} />
            <Button w='full' colorScheme='brand' onClick={() => onSubmit(audioUrl)} isDisabled={disabled || !audioUrl}>
                Submit
            </Button>
            <PoweredBy />
        </Flex>
    );
}

export default AudioRecorder;
